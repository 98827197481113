:root {
  --footer-height: 5rem;
  --desktop: 768px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

html,
body {
  width: 100%;
  min-height: 100vh;
}
body {
  background: #111;
}
.content {
  min-height: calc(100% - var(--footer-height));
  width: 100%;
  padding:7.5rem 1rem 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .content {
    padding: 15rem 1rem 1rem;
  }
}
.content > * {
  flex: 0 0 100%;
}
.content .inner {
  width: 100%;
}
.content img {
  max-width: 30rem;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  transform: translateY(15vh);
  opacity: 0;
  transition: opacity 1.5s cubic-bezier(.77, 0, .175, 1), transform 1s 1s cubic-bezier(.77, 0, .175, 1);
}
.content img.active {
  opacity: 1;
  transform: translateY(0);
}

h2 {
  color: white;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
}
.content .paragraphs {
  padding: 0 1rem;
  max-width: 50rem;
  width: 100%;
  margin: 4rem auto;
}
.content .paragraphs p {
  color: white;
  line-height: 1.2;
  font-size: 1rem;
  margin-top: 2rem;

  transition: opacity 1.5s 0.5s cubic-bezier(.77, 0, .175, 1), transform 1s 1s cubic-bezier(.77, 0, .175, 1);
  opacity: 0;
  transform: translateY(4rem);
}
.content .paragraphs p.active {
  opacity: 1;
  transform: translateY(0);
}
@media (min-width: 768px) {
  .content .paragraphs p {
    font-size: 1.3rem;
  }
}

footer {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: var(--footer-height);
  padding: 0 1rem;
}
footer > * {
  flex: 0 1 auto;
}
footer .footer-inner {
  display: flex;
  align-items: center;
}
footer h3 {
  text-align: right;
  color: white;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 600;
  text-align: center;
}
footer a {
  margin: 0 0.5rem;
}
footer a i {
  font-size: 1.4rem;
}
footer a,
footer a:visited,
footer a:active {
  color: white;
  opacity: 0.7;
}
footer a:hover {
  opacity: 1;
}
